
import { useState } from 'react';

const StatuteComponent = () => {
    const [isDownloading, setIsDownloading] = useState(false);

    const handleClick = async () => {
        setIsDownloading(true);
        try {
          const response = await fetch("./files/regulamin.pdf");
          if (!response.ok) {
            throw new Error(`Failed to download file: ${response.statusText}`);
          }
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const newWindow = window.open(url, '_blank')
        
        } catch (error) {
          console.error('Error downloading file:', error);
        } finally {
          setIsDownloading(false);
        }
      };
    

    return(
        <div className='bg'>
        <div className="data">
        <h2>Regulamin parkingu</h2>
        
        <button onClick={handleClick} disabled={isDownloading}>
        {isDownloading ? 'Pobieranie...' : 'Pobierz regulamin'}
        </button>
        </div>
        </div>
    )
}

export default StatuteComponent;