import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import ContactComponent from './Components/ContactComponent';
import StatuteComponent from './Components/StatuteComponent';
import MainComponent from './Components/MainComponent';

function App() {
  return (
    <div className='App'>
      <header>
        Stowarzyszenie Parkingowe Krępowieckiego 8
      </header>
      <BrowserRouter>
      <nav>
      <Link className='link' to='*'>Strona główna</Link>
      <Link className='link' to='/kontakt'>Kontakt</Link>
      <Link className='link' to='/regulamin'>Regulamin</Link>
      </nav>

      <Routes>
        <Route path='/kontakt' element={<ContactComponent/>}></Route>
        <Route path='/regulamin' element={<StatuteComponent/>}></Route>
        <Route path='*' element={<MainComponent/>}></Route>
      </Routes>
      </BrowserRouter>
      </div>
  );
}

export default App;
