const ContactComponent = () => {


    

    return(
        <div className="bg">
            <div className="data">
                    <h2>Informacje kontaktowe</h2>
                    <p>Telefon: 501-099-620</p>
                    <p>Adres: ul. Tadeusza Krępowieckiego 8</p>
                    <p>Numer konta: 44 1020 1185 0000 4002 0298 746</p>
                    <p>NIP: 5272607003</p>
                    <p>Regon: 141860302</p>
                    <p>KRS: 0000327804</p>
            </div>
        </div>
    );
}

export default ContactComponent;