const MainComponent = () => {
    return(
        <div className="bg">
        <div className='data'>
        <p>Witamy na stronie parkingu Krępowieckiego 8</p>
      </div>
      </div>
    )
}

export default MainComponent;